import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDrawerDispatch, useDrawerState } from 'context/DrawerContext'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import Button, { KIND, SHAPE } from 'components/Button/Button'
import {
  ButtonGroup,
  DrawerTitle,
  DrawerTitleWrapper,
  FieldDetails,
  Form,
  RowTitle,
} from 'containers/DrawerItems/DrawerItems.style'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { Col, Flex, Grid, Row } from 'components/FlexBox/FlexBox'
import DrawerBox from 'components/DrawerBox/DrawerBox'
import { FormFields, FormLabel } from 'components/FormFields/FormFields'
import Input from 'components/Input/Input'
import { Label4 } from 'baseui/typography'
import { Scrollbars } from 'react-custom-scrollbars'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
import { ChromePicker } from 'react-color'
import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from 'baseui/checkbox'
import { ColorCircleBig } from 'containers/Colors/Colors.style'
import { ColorPicker } from 'components/ColorGradientPicker'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SiteLanguageVar } from 'lib/reactiveVars'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import { removeKey } from 'utils'
import { DURATION, SnackbarProvider, useSnackbar } from 'baseui/snackbar'
import { ArrowLeft, Check } from 'baseui/icon'
import ColorProfileInput from 'components/LanguageProfiles/ColorProfileInput'
import ManufacturerProfileInput from 'components/LanguageProfiles/ManufacturerProfileInput'
import Uploader from 'components/Uploader/Uploader'
import ShopProfileInput from 'components/LanguageProfiles/ShopProfileInput'
import DefaultPaymentMethodSelect from 'components/PaymentMethodSelect/DefaultPaymentMethodSelect'
import DefaultShippingMethodSelect from 'components/ShippingMethodSelect/DefaultShippingMethodSelect'
import StyleSelect from 'components/StyleSelect/StyleSelect'
import CollectionsSelect from 'components/CollectionsSelect/CollectionsSelect'
import ParentCategorySelect from 'components/CategorySelect/ParentCategorySelect'
import DrawerSelect from 'components/Select/DrawerSelect'
import UploaderBanners from 'components/Uploader/UploaderBanners'
import PolicyProfileInput from 'components/LanguageProfiles/PolicyProfileInput'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import LanguageSettings from './LanguageSettingsForm'
import { SITE_SETTINGS } from 'settings/constants'
import { useHistory } from 'react-router-dom'
import { Notification, KIND as KINDNOTIFICATION } from 'baseui/notification'
import AboutUsProfileInput from 'components/LanguageProfiles/AboutUsProfileInput'

export const CORE_DESIGN_SETTINGS_DATA = gql`
  fragment CoreDesignSettingsData on ShopSetting {
    id
    favicon {
      id
      src
    }
    theme_main_color
    theme_secondary_color
  }
`

export const UPDATE_ABOUT_US_PAGE = gql`
  ${CORE_DESIGN_SETTINGS_DATA}
  mutation UPDATE_ABOUT_US_PAGE(
    $shopSettingsId: Int
    $favicon: [String]
    $theme_main_color: String
    $theme_secondary_color: String
  ) {
    updateDesignSettings(
      shopSettingsId: $shopSettingsId
      favicon: $favicon
      theme_main_color: $theme_main_color
      theme_secondary_color: $theme_secondary_color
    ) {
      ...CoreDesignSettingsData
    }
  }
`

// We only get the first shop settings, then we update it or create a new if its null.
export const GET_DESIGN_DATA = gql`
  ${CORE_DESIGN_SETTINGS_DATA}
  query GET_ABOUT_US_PAGE {
    findFirstShopSetting {
      ...CoreDesignSettingsData
    }
  }
`

// Validation rules....
const schema = yup.object().shape({
  // ----
})

type Props = any
const DesignSettingsForm: React.FC<Props> = () => {
  // const data = useDrawerState("data");
  // const id = data.postID;

  const { enqueue } = useSnackbar()

  //* ------------------------ DRAWER CONTEXT
  const dispatch = useDrawerDispatch()
  // ===== CLOSE DRAWER CALLBACK
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [
    dispatch,
  ])

  //* ==== Get all the posts data needed by passing the "data.postID" retrieved from Context
  const { data: { findFirstShopSetting: shopSettings } = {}, loading, error, refetch, fetchMore } = useQuery(
    GET_DESIGN_DATA,
  )

  //* ==== Mutation to edita data in database
  const [
    updateAboutUsPage,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(UPDATE_ABOUT_US_PAGE, {
    onError: (e) => {
      // For mutation errors...
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)

      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )

      // After mutation is finished - show the updated data...
      // ======== If I need to trigger an error in the profiles tab...
      // setError("profiles", {
      //   type: "manual",
      //   message: "Dont Forget Your Username Should Be Cool!",
      // });
    },
    onCompleted: (e) => {
      // Handle the success case.
      enqueue(
        {
          message: 'Saving succesful!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    refetchQueries: ['GET_ABOUT_US_PAGE'],
  })

  //=========== REACT HOOOK FORM ============
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      shop_settings_id: null,
      favicon: [],
      theme_main_color: '',
      theme_secondary_color: '',
    },
  })

  // =================================

  useEffect(() => {
    // Update Hex in state, once Graphql is finished
    if (shopSettings) {
      setValue('shop_settings_id', shopSettings.id ?? null)
      //------

      setValue(
        'favicon',
        shopSettings.favicon
          ? [shopSettings.favicon.src]
          : [],
      )
      setValue('theme_main_color', shopSettings.theme_main_color)
      setValue(
        'theme_secondary_color',
        shopSettings.theme_secondary_color,
      )

    }
  }, [setValue, shopSettings]) // We need to include called value (to refresh after mutation)

  const onSubmit = (data) => {
    // console.log('Submitting -->', data)
    // Modify form data here =============

    // // Remove __typename keys from the query.
    const modifiedData = removeKey(data, '__typename')
    // ===================================
    console.log('modifiedData (About Us Page) ->', modifiedData)

    updateAboutUsPage({
      variables: {
        shopSettingsId: modifiedData.shop_settings_id,
        favicon: modifiedData.favicon,
        theme_main_color: modifiedData.theme_main_color,
        theme_secondary_color: modifiedData.theme_secondary_color,
      },
    })
  }

  let history = useHistory()

  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()
  if (mutationError) {
    setTimeout(() => {
      executeScroll()
    }, 1000)
  }

  //* ===== always manage the loading state, or error will display "TypeError: Cannot read property 'productContainer' of undefined"
  if (loading) return <InLineLoader />
  if (error) return <p>Query Error! {error.message}</p>

  return (
    <>
      <Button
        // shape={SHAPE.circle}
        // startEnhancer={() => <ArrowLeft size={20} />}
        onClick={() => history.push(SITE_SETTINGS)}
        overrides={{
          BaseButton: {
            style: {
              padding: '0 10px',
              marginBottom: '10px',
            },
          },
        }}
      >
        <ArrowLeft size={40} />
      </Button>
      <br></br>
      <DrawerTitle>Design Settings</DrawerTitle>
      {Object.keys(errors).length > 0 ? (
        <Label4 color="red">There are ERRORS! (scroll up or down)</Label4>
      ) : null}

      <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        {/* ============== Main image  ============== */}
        <Row>
          <Col lg={3} sm={12}>
            <DrawerBox sameHeight noBackground>
              <FormLabel>FAVICON</FormLabel>
              <FieldDetails>
                Upload your favicon (optional).<br></br>
                SIZE 64 x 64 px.
              </FieldDetails>
            </DrawerBox>
          </Col>
          <Col lg={9} sm={12}>
            <DrawerBox sameHeight>
              {mutationError && (
                <div ref={myRef}>
                  <Notification
                    kind={KINDNOTIFICATION.negative}
                    overrides={{
                      Body: { style: { width: 'auto' } },
                    }}
                  >
                    {mutationError.message}
                  </Notification>
                </div>
              )}
              <Controller
                name="favicon"
                control={control}
                rules={{
                  shouldUnregister: true,
                }}
                render={({ field }) => {
                  return (
                    <Uploader
                      onChange={field.onChange}
                      images={field.value}
                      multiple={false}
                    />
                  )
                }}
              />
            </DrawerBox>
          </Col>
        </Row>

        {/* ==============  DESIGN COLORS SECTION ============== */}
        <Row>
          <Col lg={3}>
            <DrawerBox sameHeight noBackground>
              <FormLabel>DESGIN COLORS (Advanced)</FormLabel>
              <FieldDetails>
                Here you can add some design tweaks for your block.
              </FieldDetails>
            </DrawerBox>
          </Col>
          <Col lg={9}>
            <DrawerBox
              overrides={{
                Block: {
                  style: {
                    width: '100%',
                    height: 'auto',
                    padding: '30px',
                    borderRadius: '3px',
                    backgroundColor: '#ffffff',
                  },
                },
              }}
            >
              {/* <Row>
                  <Col lg={9} sm={12}>
                    <FormLabel>Default Margins (optional)</FormLabel>
                    <FieldDetails style={{ padding: 0 }}>
                      You can have spaces between blocks or not. If turned On -
                      spaced will appear. If off blocks will touch each other.
                    </FieldDetails>
                  </Col>

                  <Col lg={3} sm={12}>
                    <FormFields>
                      <Controller
                        name="default_margins"
                        control={control}
                        rules={{
                          required: true,
                          shouldUnregister: true,
                        }}
                        render={({ field }) => (
                          <Checkbox
                            checked={field.value}
                            checkmarkType={STYLE_TYPE.toggle_round}
                            onChange={field.onChange}
                            labelPlacement={LABEL_PLACEMENT.left}
                            overrides={{
                              Root: {
                                style: {
                                  float: 'right',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </FormFields>
                  </Col>
                </Row> */}

              <Row>
                <Col lg={6} sm={12}>
                  <FormFields>
                    <FormLabel>theme_main_color (optional)</FormLabel>
                    <Input
                      type="text"
                      step={1}
                      {...register('theme_main_color', { required: false })}
                      error={errors.theme_main_color ? true : false}
                      placeholder="e.g.: #eeeeee"
                    />
                    {errors.theme_main_color && (
                      <Label4 color="red">
                        {errors.theme_main_color.message}
                      </Label4>
                    )}
                  </FormFields>
                </Col>
                <Col lg={6} sm={12}>
                  <FormFields>
                    <FormLabel>theme_secondary_color (optional)</FormLabel>
                    <Input
                      type="text"
                      step={1}
                      {...register('theme_secondary_color', {
                        required: false,
                      })}
                      error={errors.theme_secondary_color ? true : false}
                      placeholder="e.g.: #ffffff"
                    />
                    {errors.theme_secondary_color && (
                      <Label4 color="red">
                        {errors.theme_secondary_color.message}
                      </Label4>
                    )}
                  </FormFields>
                </Col>
              </Row>
            </DrawerBox>
          </Col>
        </Row>

        {/* ==============  SHOW CONTACT BLOCKS ============== */}
        {/* <Row>
          <Col lg={3} sm={12}>
            <DrawerBox sameHeight noBackground>
              <FormLabel>Contact Information</FormLabel>
            </DrawerBox>
          </Col>
          <Col lg={9} sm={12}>
            <DrawerBox sameHeight>
              <Row>
                <Col lg={9} sm={12}>
                  <FieldDetails style={{ padding: 0 }}>
                    You can choose, to enable or disable contact information in
                    the about us page.
                  </FieldDetails>
                </Col>

                <Col lg={3} sm={12}>
                  <FormFields>
                    <Controller
                      name="show_contact_blocks"
                      control={control}
                      rules={{
                        required: true,
                        shouldUnregister: true,
                      }}
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          onChange={field.onChange}
                          labelPlacement={LABEL_PLACEMENT.left}
                          overrides={{
                            Root: {
                              style: {
                                float: 'right',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </FormFields>
                </Col>
              </Row>
            </DrawerBox>
          </Col>
        </Row> */}

        {/* ============== SUBMIT BUTTONS ============== */}

        <Button
          type="submit"
          disabled={mutationError}
          isLoading={mutationLoading}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                width: '90%',
                margin: '15px 15px 15px 15px',
                // marginTop: "15px",
                // marginBottom: "15px",
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                borderBottomRightRadius: '3px',
                borderBottomLeftRadius: '3px',
              }),
            },
          }}
        >
          Save
        </Button>
      </Form>
    </>
  )
}

export default DesignSettingsForm
