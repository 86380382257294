import React, { useCallback, useEffect, useState } from 'react'
import { styled, withStyle, createThemedUseStyletron } from 'baseui'
import { Grid, Row, Col } from 'components/FlexBox/FlexBox'
import Select from 'components/Select/Select'
import Input from 'components/Input/Input'
import {
  useQuery,
  gql,
  useMutation,
  useLazyQuery,
  useReactiveVar,
} from '@apollo/client'
import { Wrapper, Header, Heading } from 'components/Wrapper.style'
import ThemeButton from 'components/Button/Button'
// import {Button} from 'baseui/button';
import { Button, KIND, SIZE, SHAPE } from 'baseui/button'
import NoResult from 'components/NoResult/NoResult'
import { useDrawerDispatch } from 'context/DrawerContext'
import DataTable from 'react-data-table-component'
import { Check, Delete, Plus, Spinner } from 'baseui/icon'
import { FiEdit, FiArrowRight, FiFrown, FiEyeOff } from 'react-icons/fi'
import { RiContactsBookLine, RiDeleteBin2Line } from 'react-icons/ri'
import useDebounce from '../../utils/hooks/debounce'
import { useConfirmation } from 'context/ConfirmationServiceContext'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
import { SiteLanguageVar } from 'lib/reactiveVars'
import { largeWidth, midWidth, sm, smallWidth } from 'settings/constants'
import { extractProfileByLanguage } from 'utils/languages'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { priceFormatter } from 'utils/priceUtils'
import { Tag, VARIANT } from 'baseui/tag'
import { orderNumberFormatter } from 'utils'

import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { FlagComponent } from 'components/Flag/FlagComponent'
import { OrderDateComponent } from 'components/OrderDate/OrderDate'
import { OrderStatusComponent } from 'components/OrderStatus/OrderStatusComponent'
import { ConstructionOutlined } from '@mui/icons-material'
import {
  checkDiscountValidity,
  checkDiscountValidityBoolean,
} from 'utils/discountValidityCheck'
import usePaging from 'utils/hooks/usePaging'

// TODO Update this query to when prisma rollouts _count functionality...
// This will serch through all color profiles (no matter the language)
export const GET_B2B_CUSTOMERS = gql`
  query GET_B2B_CUSTOMERS(
    $searchTerm: String
    # $searchTermInteger: Int
    # $language: EnumLanguageFilter
    $take: Int
    $skip: Int
    $orderBy: [CustomerOrderByWithRelationInput!]
  ) {
    findManyCustomerCount(
      where: {
        OR: [
          { name: { mode: insensitive, contains: $searchTerm } }
          { surname: { mode: insensitive, contains: $searchTerm } }
          { b2b_company_name: { mode: insensitive, contains: $searchTerm } }
        ]
        is_b2b_user: { equals: true }
      }
    )

    findManyCustomer(
      orderBy: $orderBy
      where: {
        OR: [
          { name: { mode: insensitive, contains: $searchTerm } }
          { surname: { mode: insensitive, contains: $searchTerm } }
          { b2b_company_name: { mode: insensitive, contains: $searchTerm } }
        ]
        is_b2b_user: { equals: true }
      }
      take: $take
      skip: $skip
    ) {
      # ----- The customer

      id
      createdAt
      name
      surname
      email
      default_phone_number
      language
      buying_as_company

      is_b2b_user
      b2b_company_name
      b2b_customer_discount
      b2b_company_country

      orders {
        id
      }

      # is this a registered user?
      customer_account {
        id
      }
    }
  }
`

const DELETE_SINGLE_B2B_CUSTOMER = gql`
  mutation DELETE_SINGLE_B2B_CUSTOMER($id: Int!) {
    deleteSingleB2BCustomer(id: $id)
  }
`

type CustomThemeT = { red400: string; textNormal: string; colors: any }
const themedUseStyletron = createThemedUseStyletron<CustomThemeT>()

export default function B2BCustomersPage() {
  const [searchTerm, setSearchTerm] = useState('')
  // Controlling DRAWER (The edit form) dispatch from here...
  const dispatch = useDrawerDispatch()

  // ======= FORM Drawer CONTROLS ==========
  const openDrawerNewForm = useCallback(
    () =>
      dispatch({ type: 'OPEN_DRAWER', drawerComponent: 'B2B_CUSTOMER_FORM' }),
    [dispatch],
  )

  const openCustomerUpdateForm = useCallback(
    (postID) => {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'B2B_CUSTOMER_UPDATE_FORM',
        data: {
          postID,
        },
      })
    },
    [dispatch],
  )
  // ======= FORM Drawer CONTROLS (above) ==========

  // TODO add new order, when product select is finished
  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 5,
              boxShadow: '0 0 8px rgba(0, 0 ,0, 0.1)',
            }}
          >
            <Col md={3} xs={12}>
              <Heading>B2B Customers</Heading>
            </Col>

            <Col md={9} xs={12}>
              <Row>
                <Col md={8} xs={12}>
                  <SearchInput setSearchTerm={setSearchTerm} />
                </Col>

                <Col md={4} xs={12}>
                  <ThemeButton
                    style={{ width: '100%' }}
                    startEnhancer={() => <Plus size={20} />}
                    onClick={openDrawerNewForm}
                  // disabled
                  >
                    New B2B Customer
                  </ThemeButton>
                </Col>
              </Row>
            </Col>
          </Header>

          <Wrapper
            style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)', marginTop: 20 }}
          >
            <B2BCustomersTable
              searchTerm={searchTerm}
              updateRow={openCustomerUpdateForm}
            />
          </Wrapper>
        </Col>
      </Row>
    </Grid>
  )
}

const B2BCustomersTable = ({ searchTerm, updateRow }) => {
  const [useCss, theme] = themedUseStyletron()
  const confirm = useConfirmation()
  const { enqueue } = useSnackbar()
  // We want to pass the language, ONLY when we have a search term.
  const siteLanguage = useReactiveVar(SiteLanguageVar)
  let language = undefined
  if (searchTerm) {
    language = { equals: siteLanguage }
  }

  const [selectedRows, setSelectedRows] = useState(null)
  const [selectedCount, setSelectedCount] = useState(0)
  const [resetSelectedRows, setResetSelectedRows] = useState(false)
  const [orderBy, setOrderBy] = useState(undefined) // (SORTING)
  const { pageNumber, paginationPerPage, onChangePage, onChangeRowsPerPage, resetPaginationToggle, getFilters } = usePaging(1, 50);
  const [data, setData] = useState(undefined)

  // We need to set a default orderBy...
  useEffect(() => {
    setOrderBy({
      createdAt: 'desc',
    })
  }, [])

  //* ==== Mutation to edita data in database
  const [
    deleteSingleCustomer,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(DELETE_SINGLE_B2B_CUSTOMER, {
    onError: (e) => {
      // For mutation errors...
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)
      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    onCompleted: (e) => {
      // Handle the success case.
      enqueue(
        {
          message: 'Customer Deleted!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
      setResetSelectedRows(!resetSelectedRows)
    },
    refetchQueries: ['GET_B2B_CUSTOMERS', 'GET_B2B_CUSTOMERS_FOR_SELECT'],
  })

  // === GRAPHQL Query to get Colors
  const [fetchDataLazy, { loading, error, data: queryData }] = useLazyQuery(
    GET_B2B_CUSTOMERS,
    {
      variables: {
        searchTerm,
        searchTermInteger: isNaN(parseInt(searchTerm))
          ? undefined
          : parseInt(searchTerm),
        language,
        ...getFilters(),
        orderBy,
      },
      fetchPolicy: 'cache-and-network',
    },
  )

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    }
  }, [queryData])

  // We need this useEffec to control refetching of information, when state changes.
  // We fetch only when information related to the query changes.
  useEffect(() => {
    // console.log("Fetching...");
    fetchDataLazy()
  }, [
    searchTerm,
    siteLanguage,
    paginationPerPage,
    pageNumber,
    fetchDataLazy,
  ])

  // ======== DATA MIGRATION FUNCTIONS (above) =====================================================================
  // ---------------------------------------------------------------------------------------------------------------

  if (!data) return <InLineLoader />
  if (error) {
    return <div>Error! {error.message}</div>
  }

  // ================ TABLE PAGINATION ========================
  const totalRows = data.findManyCustomerCount // This variable comes from Graphql query.

  // ================ TABLE PAGINATION (above) ================
  // ================ TABLE SORTING ================
  const handleSort = (column, sortDirection: 'desc' | 'asc') => {
    switch (column.selector) {
      case 'id':
        setOrderBy({ id: sortDirection })
        break
      case 'number_of_products':
        setOrderBy({ products: { _count: sortDirection } })
        break
      default:
        setOrderBy({ createdAt: 'desc' }) // Set default to be sorted (newest first)
    }
  }
  // ================ TABLE SORTING (above) ================

  const handleAction = (id) => {
    // Update drawer open is in the parent component
    updateRow(id)
  }

  const handleActionPdf = (pdf_link) => {
    console.log('PDF link -> ', pdf_link)
    window.open(pdf_link, '_blank')
  }

  const handleDelete = (id) => {
    // Confirm pops up a dialog asks if user wants to continue?
    confirm({
      variant: 'danger',
      title: 'Are you sure?',
      description: 'You will delete this commercial offer',
    }).then(() => deleteOneCustomer(id))
  }

  const deleteOneCustomer = (id) => {
    // Delete this id.
    deleteSingleCustomer({
      variables: { id },
    })
  }

  const deleteMany = async () => {
    // selectedRows --> Delete all in this list
    if (selectedRows) {
      const itemsToDelete = await extractIDsToList(selectedRows)
      confirm({
        variant: 'danger',
        // catchOnCancel: true,
        title: `Are you sure, you want to DELETE (${selectedCount}) B2B Customers?`,
        description: '...',
      }).then(() => {
        // console.log("Deleting many colors -->", colorsToDelete);
        for (const itemId of itemsToDelete) {
          deleteSingleCustomer({
            variables: { id: itemId },
          })
        }
      })
    } else {
      // Say something...
      alert('Nothing selected..')
    }
  }

  const handleSelectedRowsChange = ({
    allSelected,
    selectedCount,
    selectedRows,
  }) => {
    setSelectedRows(selectedRows)
    setSelectedCount(selectedCount)
  }

  //This is a UTILITY FUNCTION. Not moving out, because might be closely associated with table rows.
  const extractIDsToList = (list) => {
    let newList = [] as any
    for (const item of list) {
      if (item.id) {
        newList.push(item.id)
      } else {
        alert('Selected items have no ids...')
      }
    }
    return newList
  }

  const columns = [
    {
      name: 'ID',
      selector: 'id',
      hide: sm,
      sortable: true,
      width: smallWidth,
    },

    {
      name: 'Date',
      selector: 'createdAt',
      hide: sm,
      sortable: true,
      width: largeWidth,
    },

    {
      name: `Name`,
      selector: 'name',
      sortable: false,
    },
    {
      name: `Company`,
      selector: 'company',
      sortable: false,
    },

    {
      name: `B2B`,
      selector: 'is_b2b_user',
      sortable: false,
    },

    // ---- NOT DECIDED YET IF USABLE

    {
      name: `Number of Orders`,
      selector: 'number_of_orders',
      sortable: false,
    },

    // {
    //   name: `Number of Commercial Offers`,
    //   selector: 'number_of_commercial_offers',
    //   sortable: false,
    // },

    {
      name: 'Delete',
      sortable: false,
      button: true,
      cell: (row) => (
        <Button
          kind={KIND.minimal}
          size={SIZE.compact}
          shape={SHAPE.circle}
          onClick={() => handleDelete(row.id)}
        >
          <RiDeleteBin2Line color={'red'} size={'1.3em'} />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      width: smallWidth,
    },

    {
      name: 'View',
      sortable: false,
      button: true,
      cell: (row) => (
        <Button
          kind={KIND.minimal}
          size={SIZE.compact}
          shape={SHAPE.circle}
          onClick={() => handleAction(row.id)}
        >
          <FiArrowRight size={'1.5em'} />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      width: smallWidth,
    },
  ]

  const dataModified = data.findManyCustomer.map((customer) => {
    // const collectionProfile = extractProfileByLanguage(
    //   siteLanguage,
    //   collection.collection_profiles
    // );

    const name = customer ? `${customer.name} ${customer.surname}` : '(empty)'
    const company = customer.b2b_company_name ? customer.b2b_company_name : '-'

    return {
      id: customer.id,
      createdAt: <OrderDateComponent dateTime={customer.createdAt} />,
      name: <strong>{name}</strong>,
      company: company,
      is_b2b_user: customer.is_b2b_user ? 'YES' : 'NO',
      b2b_company_country: customer.b2b_company_country ?? '-',
      number_of_orders: customer.orders.length,
      // locale: <FlagComponent locale={order.order_locale} />,
    }
  })

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  }

  return (
    <Grid fluid={true}>
      <DataTable
        // title="Colors List" // Not displayed when noHeader=true
        customStyles={customStyles}
        columns={columns}
        data={dataModified}
        striped={true}
        highlightOnHover={true}
        pointerOnHover={true}
        onRowClicked={(row) => handleAction(row.id)}
        paginationRowsPerPageOptions={[25, 50, 100, 200]}
        // subHeader={false}

        subHeader={true}
        subHeaderComponent={
          <SubHeader
            onDelete={deleteMany}
            rowsSelected={selectedCount > 0 ? true : false}
            rowsCount={selectedCount}
            loading={loading}
          />
        }
        noContextMenu={true} // To remove the blue bar showing how many rows you have selected
        // noHeader={true}
        noDataComponent={
          <NoResult
            hideButton={true}
            message={
              "No commercial offers yet! Hopefully you'll make some soon! :)"
            }
          />
        }
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={paginationPerPage}
        sortServer
        onSort={handleSort}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        paginationResetDefaultPage={resetPaginationToggle}
        selectableRows
        onSelectedRowsChange={handleSelectedRowsChange}
        clearSelectedRows={resetSelectedRows}
        disabled={loading}
      />
    </Grid>
  )
}

const SearchInput = ({ setSearchTerm }) => {
  const [searchString, setSearchString] = useState('')
  const debouncedSearchTerm = useDebounce(searchString, 500)

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearchTerm(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm, setSearchTerm])

  const handleEnter = (e) => {
    // Handling Enter Clicks
    if (e.charCode === 13) {
      setSearchTerm(searchString)
    }
  }

  const handleInputChange = (e) => {
    if (!e.target.value) {
      setSearchString(e.target.value)
      setSearchTerm(e.target.value)
    } else {
      setSearchString(e.target.value)
    }
  }

  return (
    <Input
      value={searchString}
      onChange={handleInputChange}
      placeholder="Search by name"
      clearable
      clearOnEscape
      onKeyPress={handleEnter}
    />
  )
}

// TODO ========= SORTTING RECENT AT TOP!!!!!!
const SubHeader = ({ onDelete, rowsSelected, rowsCount, loading }) => {
  return (
    <>
      <Row style={{ width: '100%', padding: 0 }}>
        <Col md={12}>
          <Col md={6} style={{ float: 'left' }}>
            <Button
              kind={KIND.secondary}
              size={SIZE.compact}
              disabled
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.textNormal,
                    marginLeft: '-40px',
                  }),
                },
              }}
            >
              Selected: {rowsCount}
            </Button>
            {loading ? <Spinner /> : null}
          </Col>
          <Col md={6} style={{ float: 'right' }}>
            <Button
              onClick={onDelete}
              size={SIZE.compact}
              disabled={!rowsSelected}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    backgroundColor: $theme.colors.red400,
                  }),
                },
              }}
            >
              Delete
            </Button>
          </Col>
        </Col>
      </Row>
    </>
  )
}
